var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.pointContent"),
                clearable: ""
              },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm.listQuery.type === 0
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.date"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("新增人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.newUser))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3673686379
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.totalNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3604340018
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("总充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.totalAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  284194344
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("其他充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.otherAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3233185166
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1818851692
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2914219273
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  546847796
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinNum1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3314683877
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3362678702
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderClick))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  986477824
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderCoin))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4155050981
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.snakeLadderNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3066581976
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderNum1))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1218291209
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderAmount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2459759298
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1210924640
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  639348581
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  793769656
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedNum1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  693820105
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2723143010
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3158461633
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3013082916
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4078648985
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomNum1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  971651784
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.voiceRoomAmount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2738091395
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineClick))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  374224177
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineCoin))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3554505524
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineCount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2306115548
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineNum))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2547194473
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineNum1))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1059477400
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineAmount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  585267635
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1972110568
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2502968557
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4198091141
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3856258608
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuNum1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2738561345
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2846150378
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3287343622
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  701412867
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2280829291
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.labaNum))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2945678174
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸充值人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaNum1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3383182895
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸充值金额"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaAmount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3732290052
                )
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("default.operate"),
                  "min-width": "160"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 1
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.date"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("新增人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.newUser))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3673686379
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("次日留存"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.newUser1))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2588086746
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1818851692
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2914219273
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("mdagsh参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albcoinNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  546847796
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderClick))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  986477824
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.snakeLadderCoin))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4155050981
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("蛇参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.snakeLadderNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3066581976
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1210924640
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  639348581
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("极速参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.albSpeedNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  793769656
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3158461633
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3013082916
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("语音房参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.voiceRoomNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4078648985
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineClick))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  374224177
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineCoin))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3554505524
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineCount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2306115548
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("水果机参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.fruitMachineNum))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2547194473
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1972110568
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2502968557
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4198091141
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("牛牛参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.niuNiuNum))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3856258608
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸点击数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaClick))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3287343622
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸金币数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaCoin))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  701412867
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸下注次数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.labaCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2280829291
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("拉霸参与人数"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.labaNum))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2945678174
                )
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("default.operate"),
                  "min-width": "160"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }