var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("statManager.inputGoodsId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.goodsId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "goodsId", $$v)
              },
              expression: "listQuery.goodsId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("statManager.inputOriginalId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.originalId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "originalId", $$v)
              },
              expression: "listQuery.originalId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("statManager.inputOrderId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.orderId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "orderId", $$v)
              },
              expression: "listQuery.orderId"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: {
                placeholder: _vm.$t("statManager.provider"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.provider,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "provider", $$v)
                },
                expression: "listQuery.provider"
              }
            },
            _vm._l(_vm.typeProviders, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: _vm.$t("statManager.type"), clearable: "" },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.typeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.state"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.state,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state"
              }
            },
            _vm._l(_vm.states, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: {
                  label: item.name + "(" + item.key + ")",
                  value: item.key
                }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: {
                placeholder: _vm.$t("statManager.firstChargeTip"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.firstCharge,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "firstCharge", $$v)
                },
                expression: "listQuery.firstCharge"
              }
            },
            _vm._l(_vm.typeFirsts, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.handleExport }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.export")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.supplementOrder1()
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.supplement")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("agents.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "100px",
              "class-name": _vm.getSortClass("id")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.playerId"),
              width: "100px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.provider"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getProvider(scope.row.provider)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.originalId"),
              width: "270px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.originalId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.state"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getOrderState(
                            scope.row.state,
                            scope.row.cancleType
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.firstChargeTip"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getFirstCharge(scope.row.firstCharge)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.orderId"),
              width: "270px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderId
                      ? _c("div", [
                          _c("span", [_vm._v(_vm._s(scope.row.orderId))])
                        ])
                      : _c("div", [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  staticClass: "filter-item",
                                  attrs: {
                                    type: "warning",
                                    icon: "el-icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.supplementOrder(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("statManager.supplement")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.goodsId"),
              width: "160px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.goodsId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount"),
              width: "60px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.count"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.count))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("dashboard.type"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTypeValue(scope.row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.sar"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.sar))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.giveUid"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.to))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.createTime"),
              width: "150px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.updateTime"),
              width: "150px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTime(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("statManager.supplement"),
            visible: _vm.dialogVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "160px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "id", label: "ID" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "id", $$v)
                      },
                      expression: "formData.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "uid", label: _vm.$t("user.playerId") } },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.uid,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "uid", $$v)
                      },
                      expression: "formData.uid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "provider",
                    label: _vm.$t("statManager.provider")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.providerStr,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "providerStr", $$v)
                      },
                      expression: "formData.providerStr"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "originalId",
                    label: _vm.$t("statManager.originalId")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.originalId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "originalId", $$v)
                      },
                      expression: "formData.originalId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "state", label: _vm.$t("statManager.state") }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.stateStr,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "stateStr", $$v)
                      },
                      expression: "formData.stateStr"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "goodsId",
                    label: _vm.$t("statManager.goodsId")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.goodsId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "goodsId", $$v)
                      },
                      expression: "formData.goodsId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "amount", label: _vm.$t("statManager.amount") }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "amount", $$v)
                      },
                      expression: "formData.amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "count", label: _vm.$t("statManager.count") }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.count,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "count", $$v)
                      },
                      expression: "formData.count"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("statManager.type") } },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.typeStr,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "typeStr", $$v)
                      },
                      expression: "formData.typeStr"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "sar", label: _vm.$t("statManager.sar") } },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.sar,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "sar", $$v)
                      },
                      expression: "formData.sar"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "to", label: _vm.$t("statManager.giveUid") } },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.to,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "to", $$v)
                      },
                      expression: "formData.to"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "orderId",
                    label: _vm.$t("statManager.orderId")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("statManager.inputOrderId"),
                      type: "text"
                    },
                    model: {
                      value: _vm.formData.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "orderId", $$v)
                      },
                      expression: "formData.orderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "warning", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          return _vm.updateOrder("formData")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("statManager.supplement")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("statManager.supplement"),
            visible: _vm.dialogVisible1,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible1 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleData,
                "label-width": "160px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "id", label: "ID" } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "id", $$v)
                      },
                      expression: "formData.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "uid", label: _vm.$t("user.playerId") } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.uid,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "uid", $$v)
                      },
                      expression: "formData.uid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "orderId",
                    label: _vm.$t("statManager.orderId")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("statManager.inputOrderId"),
                      type: "text"
                    },
                    model: {
                      value: _vm.formData.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "orderId", $$v)
                      },
                      expression: "formData.orderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "goodsId",
                    label: _vm.$t("statManager.goodsId")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.goodsId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "goodsId", $$v)
                      },
                      expression: "formData.goodsId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "warning", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          return _vm.updateOrder("formData")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("statManager.supplement")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }