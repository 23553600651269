var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.pointContent"),
                clearable: ""
              },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("输入vid"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.vid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "vid", $$v)
              },
              expression: "listQuery.vid"
            }
          }),
          _vm.listQuery.type === 0
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("输入aid"), clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.aid,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "aid", $$v)
                  },
                  expression: "listQuery.aid"
                }
              })
            : _vm._e(),
          _vm.listQuery.type === 1
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: _vm.$t("user.inputPlayerId"),
                  clearable: ""
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "uid", $$v)
                  },
                  expression: "listQuery.uid"
                }
              })
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm.listQuery.type === 0
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("时间"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("aid"), width: "60px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.aid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2632776106
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vid"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.vid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  654721565
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("owner"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.owner))])]
                      }
                    }
                  ],
                  null,
                  false,
                  175051815
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("name"),
                  width: "320px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2020036417
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("imgurl"),
                  width: "320px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.imgurl))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2976159982
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("explain"),
                  width: "220px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.explain))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3708850945
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("weight"),
                  width: "220px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.weight))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1766064422
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("online"),
                  width: "220px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.online))])]
                      }
                    }
                  ],
                  null,
                  false,
                  618756169
                )
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("default.operate"),
                  "min-width": "160"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.editItem(scope.row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("default.edit")) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2912509228
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 1
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("时间"),
                  width: "200px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("aid"), width: "60px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.aid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2632776106
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("uid"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1905034046
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("type"),
                  width: "100px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.type))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1406222782
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("speak"),
                  width: "320px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.speak))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2324448362
                )
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("default.operate"),
                  "min-width": "160"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.editItem(scope.row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("default.edit")) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2912509228
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operation.addAndEdit"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _vm.listQuery.type === 1
            ? _c(
                "el-form",
                {
                  ref: "formData",
                  staticClass: "activity-form",
                  attrs: { model: _vm.formData, "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "type",
                        label: _vm.$t("type")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.type,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "type", $$v)
                          },
                          expression: "formData.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "type",
                        label: _vm.$t("speak")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.speak,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "speak", $$v)
                          },
                          expression: "formData.speak"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "button",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveOrUpdate("formData")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("default.save")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.listQuery.type === 0
            ? _c(
                "el-form",
                {
                  ref: "formData",
                  staticClass: "activity-form",
                  attrs: { model: _vm.formData, "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "name",
                        label: _vm.$t("name")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "imgurl",
                        label: _vm.$t("imgurl")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.imgurl,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "imgurl", $$v)
                          },
                          expression: "formData.imgurl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "explain",
                        label: _vm.$t("explain")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.explain,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "explain", $$v)
                          },
                          expression: "formData.explain"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        prop: "weight",
                        label: _vm.$t("weight")
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formData.weight,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "weight", $$v)
                          },
                          expression: "formData.weight"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "button",
                      {
                        staticClass: "el-button el-button--primary",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveOrUpdate("formData")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("default.save")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }