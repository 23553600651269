var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.pointContent"),
                clearable: ""
              },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-download" },
              on: {
                click: function($event) {
                  return _vm.handlePropExport()
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.export")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          attrs: { data: _vm.list, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.date"),
              prop: "date",
              align: "center",
              width: "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                }
              }
            ])
          }),
          [3, 2, 0].indexOf(_vm.listQuery.type) >= 0
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("media_source"),
                  prop: "date",
                  align: "center",
                  width: "180px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.media_source))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4000612576
                )
              })
            : _vm._e(),
          [3, 2].indexOf(_vm.listQuery.type) >= 0
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("agency"),
                  prop: "date",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.ad))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3217640387
                )
              })
            : _vm._e(),
          _vm.listQuery.type === 3
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("campaign"),
                  prop: "date",
                  align: "center",
                  width: "420px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.campaign))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2745712312
                )
              })
            : _vm._e(),
          _vm.listQuery.type === 4
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.organic"),
                  prop: "date",
                  align: "center",
                  width: "120px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.organic))])]
                      }
                    }
                  ],
                  null,
                  false,
                  830645113
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num1"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num1))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount1))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num2"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num2))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount2))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num3"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num3))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount3))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num7"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num7))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount7))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num15"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num15))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount15))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num30"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num30))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount30))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num60"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num60))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount60))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.num90"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num90))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.amount0"),
              width: "90px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount90))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("default.operate"),
              "min-width": "160"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }